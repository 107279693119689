.disclaimer {
  background: #EDE7FF;
  padding: 76px 0;
  @include media("<=810px")  {
    
  }
  .section__title {
    text-align: center;
  }
}

.disclaimer__inner {
    display: flex;
  justify-content: center;
  align-items: center;
 text-align: center;
 font-size: 20px;
font-style: normal;
font-weight: 300;
padding-top: 40px;
color: #000;
 p {
    max-width: 721px;
 }
}

