.header {
  width: 100%;
  position: absolute;
  padding: 20px 0 ;
  background: rgba(255, 255, 255, 0.10);
  top: 0;
  left: 0;
  z-index: 20;
  @include media("<=1080px") {
    
  }
  @include media("<=840px") {
    height: 80px;
  }
  @include media("<=430px") {
    
  }
 
}

.header__inner {
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 30;
  @include media("<=1080px") {
  
      margin: 0 auto;
    }
  @include media("<=810px") {
    
  }
}

.header__nav {
  flex: 0 1 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 8px;
  @include media("<=1080px") {
    
  }

  @include media("<=810px") {
    display: none;
    max-width: 100%;

    &.active {
      display: flex;
      position: fixed;
      height: 60%;
      width: 100%;
      max-width: none;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: #EDE7FF;
      box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.25);
      z-index: 20;
      margin-right: 0;
      margin-left: 0;
      padding: 20px 20px;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      opacity: 1;

      @include media("<=430px") {
        padding-bottom: 0;

      }

      .header__link {
        padding: 15px;

        @include media("<=430px") {
         
        }

        @include media("<=350px") {
          
        }
      }
    }
  }
}

.header__link {
  padding: 14px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: #221847;
  transition: all 0.3s ease;
  position: relative;

  @include media("<=810px") {
    font-size: 18px;
  }

  &:hover {
    
  }
  &-active {
    border-radius: 8px;
border: 1.111px solid #221847;
  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  background: #fff;
  bottom: -6px;
  left: 0;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}


.logo {
  color: #221847;
font-size: 32px;
font-style: normal;
font-weight: 900;
line-height: normal;
text-transform: uppercase;
text-decoration: none;
  position: relative;
  img {
    width: 100%;
  }
}
