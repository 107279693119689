.info {
  border: 1px solid #000;
  background: #EDE7FF;
  padding: 24px;
  margin: 10px 0;
  font-size: 24px;
  font-weight: 500;
  @include media("<=430px") {
    font-size: 18px;
  }
}


.info__title {
  font-size: 24px;
  font-weight: 500;
}