.features {
  position: relative;
  padding: 60px 0;
  overflow: hidden;
  
  @include media("<=1440px") {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
}

.features__inner {
  padding-top: 48px;
  display: flex;
    gap: 40px;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    z-index: 3;
    @include media("<=1440px") {
      

    }
        @include media("<=1080px") {
          gap: 16px;
        }
                @include media("<=810px") {
                   flex-direction: column;
                   align-items: center;
                   gap: 30px;
                }
        @include media("<=650px") {
          flex-direction: column;
          
        }
}

.features__item {
  line-height: 1.2;
  padding: 24px;
  flex: 0 1 30%;
  border-radius: 8px;
border: 2px solid #221847;
background: #8D69F9;
  font-size: 18px ;
  font-weight: 600;
  color: #fff;
  @include media("<=810px") {
    flex: 0 1 100%;
  }
  span {
    font-weight: 800;
    padding-bottom: 12px;
  }
  &-big {
    flex: 0 1 48%;
  }
}
 




