body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background: #fff;
  color: #000;
  position: relative;

  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1030px;
  padding: 0 15px;
  margin: 0 auto;
  
}

.section__title {
  
  text-transform: uppercase;
  color: #4137A0;
 
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @include media("<=810px") {
      
    }
        @include media("<=430px") {
          font-size: 28px;
        }
}

.wrapper {
  
}

//BURGER BUTTON//
.burger__button {
  display: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #fff;
  @include media("<=810px") {
    display: flex;
    flex-direction: column;
    justify-content: center;
  align-items: center;
    gap: 4px;
    position: fixed;
    top: 16px;
    right: 40px;
    z-index: 200;
  }

  @include media("<=430px") {
   
    right: 20px;
  }

  @include media("<=810px") {
    &.active {
      position: fixed;
      top: 16px;
      right: 40px;
      z-index: 200;
    }

    @include media("<=430px") {
      &.active {
      
        right: 20px;
      }
    }
  }

  .line {
    height: 4px;
    width: 30px;
    border-radius: 4px;
    background-color: #000;
    transition: all 200ms ease-in-out;
    opacity: 1;

    @include media("<=736px") {
      
    }
  }

  &.active {
    .line {
      background-color: #000;

      &.one {
        transform: rotate(45deg) translateY(5px) translateX(6px);
      }

      &.two {
        transform: rotate(-45deg)  translateY(0px) translateX(1px);

        @include media("<=736px") {
          
        }
      }

      &.three {
        opacity: 0;
      }
    }
  }
}

.page {
  background-color: #fff;
  padding: 80px 0;
  color: #000;
}

.page__title {
  color: #FFF;
text-align: center;
text-shadow: 4px 4px 0px #000;
font-family: Handjet;
font-size: 60px;
font-style: normal;
font-weight: 900;
line-height: normal;
letter-spacing: 12px;
text-transform: uppercase;
@include media("<=430px") {
  font-size: 40px;
}
}

.page__inner {
  padding: 40px 0;
  font-size: 18px;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  strong {
    font-weight: 600;
    display: block;
    padding: 10px 0 16px;
  }
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
}

.page__cards {
  margin-top: 30px;
  padding: 50px 15px;
  background: linear-gradient(180deg, #8D69F9 0%, #221847 100%);

}

.cards__title {
  color: #15CFDC;
text-align: center;
font-size: 38px;
font-style: normal;
font-weight: 900;
line-height: normal;
text-transform: uppercase;
margin-bottom: 8px;
@include media("<=430px") {
  font-size: 24px;
}
}

.page__row {
  display: flex;
align-items: center;
justify-content: center;
gap: 20px;
max-width: 740px;
margin: 0 auto;
}

.page__item {
  flex: 0 1 24%;
  img {
    width: 100%;
  }
}
