
.contacts__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    @include media("<=810px") {
        flex-direction: column-reverse;
    }
}

.contacts__content {
    max-width: 480px;
    width: 100%;
}


.form {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    
    @include media("<=430px") {
        
    }
}

.form__input {
    width: 100%;

    input {
        border-radius: 8px;
background: rgba(214, 214, 214, 0.40);
        width: 100%;
        font-size: 16px;
        color: #0D0D0D;
        padding: 18px 16px;
        border: none;
        font-family: "Roboto", sans-serif;
        @include media("<=430px") {
            border-radius: 20px;
        }
    }
    textarea {
        resize: none;
        width: 100%;
            font-size: 16px;
            color: #0D0D0D;
            border-radius: 8px;
background: rgba(214, 214, 214, 0.40);
                padding: 18px 30px;
            border: none;
            font-family: "Roboto", sans-serif;
                @include media("<=430px") {
                    border-radius: 20px;
                }
    }
    
}

.button {
    border-radius: 8px;
border: 1px solid #221847;
background: #4137A0;
display: flex;
padding: 16px;
justify-content: center;
align-items: center;
align-self: stretch;
width: 100%;
color: #F0F0F0;
text-align: center;
}

.contacts__image {
    max-width: 480px;
    width: 100%;
    img {
        width: 100%;
    }
}