.gallery {
    padding: 70px 0;
    .section__title {
        text-align: center;
    }
}

.gallery__text {
    padding-top: 20px;
    text-align: center;
}

.gallery__inner {
    padding-top: 30px;
    display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
gap: 20px;
@include media("<=550px") {
    flex-direction: column;
}
}

.gallery__item {
    flex: 0 1 48%;
    border-radius: 8px;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.40);
overflow: hidden;
@include media("<=550px") {
    flex: 0 1 100%;
}
    img {
        width: 100%;
        display: block;
    }
}

