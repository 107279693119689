.footer {
padding: 80px 0;
background: #221847;
    @include media("<=430px") {
  padding: 60px 0;
    }
}

.footer__inner {
    justify-content: space-between;
    align-items: center;
    gap: 30px;
        display: flex;
        align-items: center;
    
    @include media("<=640px") {
        flex-direction: column;
    
  
    }
}

.footer__links {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    @include media("<=600px")  {
        flex-direction: column;
        align-items: center;
    }
}

.footer__link {
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
}

.footer__logo {
    color: #fff;
font-size: 32px;
font-style: normal;
font-weight: 900;
line-height: normal;
text-transform: uppercase;
text-decoration: none;
  position: relative;
    span {
        display: block;
        font-size: 32px;
    }
}

.copyright {
    width: 100%;
    padding-top: 20px;
    font-size: 15px;
  color: #fff;
  opacity: 0.5;
  text-align: center;
    @include media("<=430px") {
        padding-top: 30px;
    }
}
