.about {
    padding: 60px 0;
    background: #EDE7FF;
    @include media("<=500px") {
        padding-bottom: 80px;
    }
}

.about__inner {
    width: 100%;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    @include media("<=810px") {
      flex-direction: column;
      text-align: center;
    }
}

.about__image {

        width: 100%;
        img {
            width: 100%;
        }
}

.about__text {
    color: #000;
    padding-top: 20px;
    display: flex;
        justify-content: center;
        flex-direction: column;
        row-gap: 18px;
    font-size: 20px;
    line-height: 1.3;
    @include media("<=430px") {
        font-size: 16px;
    }
}