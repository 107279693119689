.hero {
  padding: 225px 0;
  background-image: url(../images/hero-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
//  z-index: 2;
  overflow-x: clip;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
     padding: 300px 0;
    
  }
  @include media("<=430px") {
      padding: 260px 0;
      
  }
  &-page {
    background-image: none;
    background-color: #33628D;
  }
}

.hero__inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
 text-align: center;
  @include media("<=1080px") {
    margin: 0 auto;
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
  }
  @include media("<=430px") {
    
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
}

.hero__content {
  flex: 0 1 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  color: #fff;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
  
    
  }
  @include media("<=810px") {
    
    margin: 0 auto;
  }
    @include media("<=500px") {
     flex-direction: column;
     align-items: center;
     
    }
}

.hero__title {
  color: #FFF;
text-align: center;
text-shadow: 8px 8px 0px #000;
-webkit-text-stroke-width: 2;
-webkit-text-stroke-color: #000;
font-family: Handjet;
font-size: 140px;
font-style: normal;
font-weight: 900;
line-height: normal;
letter-spacing: 24px;
text-transform: uppercase;
  opacity: 0;
  line-height: 1.2;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
        @include media("<=1440px") {
         
        }
  @include media("<=1280px") {
     
  }

  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   text-align: center;
   max-width: 500px;
  
  }
  @include media("<=600px") {
    font-size: 72px;
   
  }

  @include media("<=450px") {
    font-size: 64px;
  }
  @include media("<350px") {
    font-size: 56px;
  }
  span {
    font-size: 40px;
    display: block;
  }
}


.hero__text {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.link {
  
  padding: 11px;
  max-width: 214px;
  width: 100%;
  border-radius: 4px;
border: 1.111px solid #000;
background: #15CFDC;
padding: 14px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
color: #000;
font-size: 14px;

text-transform: uppercase;
transition: all 0.3s ease;
    @include media("<=1440px") {
      
    }
        @include media("<=810px") {
          margin: 0 auto;
        }
    @include media("<=430px") {
      
    }
        @include media("<=375px") {
          
        }
 &:hover {
  background-color: #FFF;
  color: #4137A0;
 }
    }
  






